<template>
  <div class="main--body--content">
    <div class="gf_col-xs-12 d-flex button-add-item px-0">
      <div class="gf_col-sm-2 text-left">
        <VBtn
          color="primary"
          class="text-normal mr-8"
          type="button"
          @click="back()"
        >
          <VIcon class="mr-2" dense>fa-undo</VIcon>
          <span>Back</span>
        </VBtn>
      </div>
    </div>
    <div class="tab_content d-flex flex-column gf_btn-block">
      <form>
        <div class="gf_col-sm-12 gf_col-md-12 form-group gf_mt-10">
          <label class="gf_col-sm-6 gf_col-md-6"
            >Shopify Domain:<span class="gf_text-danger">*</span></label
          >
          <div class="gf_col-sm-12 gf_mt-10">
            <VTextField
              outlined
              dense
              :rules="[validateShopifyDomain(false)]"
              placeholder="Shopify Domain"
              v-model="data.shopifyDomain"
            />
          </div>
        </div>
        <div class="gf_col-sm-12 gf_col-md-12 form-group gf_mt-10">
          <label class="gf_col-sm-6 gf_col-md-6"
            >Title:<span class="gf_text-danger">*</span></label
          >
          <div class="gf_col-sm-12 gf_mt-10">
            <VTextField
              outlined
              dense
              placeholder="Title"
              v-model="data.title"
            />
          </div>
        </div>
        <div class="gf_col-sm-12 gf_col-md-12 form-group gf_mt-10">
          <label class="gf_col-sm-1"
            >Price:<span class="gf_text-danger">*</span></label
          >
          <div class="gf_col-sm-12 gf_mt-10">
            <VTextField
              outlined
              dense
              placeholder="Price"
              v-model="data.price"
              suffix="$"
              v-number-only
            />
          </div>
        </div>
        <div class="gf_col-sm-12 gf_col-md-12 form-group gf_mt-10">
          <label class="gf_col-sm-6 gf_col-md-6">Note:</label>
          <div class="gf_col-sm-12 gf_mt-10">
            <VTextarea outlined dense placeholder="Note" v-model="data.note" />
          </div>
        </div>

        <div class="gf_col-xs-12 d-flex button-add-item justify-center mt-6">
          <VBtn
            color="primary"
            class="text-normal mr-8"
            type="button"
            @click="submitForm()"
          >
            <VIcon class="mr-2" dense>fa-upload</VIcon>
            <span>
              Create Purchase
            </span>
          </VBtn>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import purchaseAPI from "@/api/purchases.js";

export default {
  name: "CreatePurchase",
  data() {
    return {
      data: {
        shopifyDomain: "",
        price: "",
        title: "",
        note: ""
      }
    };
  },
  methods: {
    ...mapMutations({
      OpenSuccess: "OpenSuccess",
      OpenError: "OpenError",
      OpenLoading: "OpenLoading",
      CloseLoading: "CloseLoading"
    }),
    back() {
      this.$router.push({ name: "ListPurchase" });
    },
    submitForm() {
      const isValid = this.validateShopifyDomain(true);
      if (!isValid) return;
      const data = {
        shopifyDomain: this.data.shopifyDomain.trim(),
        price: this.data.price.trim(),
        title: this.data.title,
        note: this.data.note
      };
      if (data.shopifyDomain == "" || data.price == "" || data.title == "") {
        this.OpenError({
          message: "<p>One or more fields are empty, please check</p>"
        });
        return;
      }
      this.OpenLoading();
      purchaseAPI
        .createPurchase(data)
        .then(res => {
          this.CloseLoading();
          if (res.data.status) {
            this.OpenSuccess({
              message: "Create Purchase successfully",
              time: 1000
            });
            window.location.href = "/purchase";
          } else {
            this.OpenError({ message: "Save failed", time: 1000 });
          }
        })
        .catch(() => {
          this.CloseLoading();
        });
    },
    validateShopifyDomain(isAutoDomain = false) {
      const affix = "myshopify.com";
      // const affixes = [];
      const pattern = /^([-a-zA-Z0-9@_~]{2,256}\.)+myshopify.com$/;
      const domain = this.data.shopifyDomain || this.data.shopifyDomain.trim() || "";
      if (!domain || !domain.length) {
        return "Shopify domain should not be empty";
      }

      if (domain.endsWith(".")) {
        if (domain.indexOf("." + affix) > -1) {
          return 'Shopify domain should end with ".myshopify.com"';
        }
        const autoDomain = domain + affix;
        if (pattern.test(autoDomain) && isAutoDomain) {
          this.data.shopifyDomain = autoDomain;
          return true;
        } else {
          return 'Shopify domain should end with ".myshopify.com"';
        }
      } else {
        if (domain.indexOf("." + affix) > -1) {
          return true;
        }
        const autoDomain = domain + "." + affix;
        if (pattern.test(autoDomain) && isAutoDomain) {
          this.data.shopifyDomain = autoDomain;
          return true;
        } else {
          return 'Shopify domain should end with ".myshopify.com"';
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.mr-8 {
  margin-right: 8px;
}

input[type="text"],
select {
  width: 50%;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.width50 {
  width: 50%;
}

::v-deep .gpa-tabs {
  &__item {
    font-weight: bold;

    &:hover,
    &_active {
      background: #272822;
      color: #fff;
    }
  }
}
.gf_text-danger {
  color: red;
}
</style>
